import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CartItemList = Loadable(lazy(() => import('./CartItemList')));
const CartView = Loadable(lazy(() => import('./cartView')));

const cartItemRoutes = [
  {
    path: '/cartItem',
    element: <CartItemList />,
  },
  {
    path: '/cartItem/view/:id',
    element: <CartView />,
  },
  {
    path: '/cartItem/delete/:id',
    element: <CartItemList />,
  },
];

export default cartItemRoutes;
