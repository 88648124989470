import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import TestComponents from './TestComponents';

const ProductList = Loadable(lazy(() => import('./ProductList')));
const AddEditProduct = Loadable(lazy(() => import('./AddEditProduct')));
const ProductDetails = Loadable(lazy(() => import('./ProductDetails')));

const productRoutes = [
  {
    path: '/test',
    element: <TestComponents />,
  },
  {
    path: '/product',
    element: <ProductList />,
  },
  {
    path: '/product/create',
    element: <AddEditProduct />,
  },
  {
    path: '/product/edit/:id',
    element: <AddEditProduct />,
  },
];

export default productRoutes;
