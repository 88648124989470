import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ProductPriceMaster = Loadable(lazy(() => import('./ProductPriceMaster')));
const AddEditProductPriceMaster = Loadable(lazy(() => import('./AddEditProductPriceMaster')));

const productPriceMasterRoutes = [
  {
    path: '/productPrice',
    element: <AddEditProductPriceMaster />,
  },
  {
    path: '/productPrice/create',
    element: <AddEditProductPriceMaster />,
  },
  {
    path: '/productPrice/edit/:id',
    element: <AddEditProductPriceMaster />,
  },
  {
    path: '/productPrice/delete/:id',
    element: <ProductPriceMaster />,
  },
  //  /
];

export default productPriceMasterRoutes;
