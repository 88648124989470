import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const RegisterList = Loadable(lazy(() => import('./RegisterList')));

const registerRoutes = [
  // {
  //   path: '/register',
  //   element: <RegisterList />,
  // },
];

export default registerRoutes;
