import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const UserCategoryList = Loadable(lazy(() => import('./UserCategoryList')));
const AddEditUsercategory = Loadable(lazy(() => import('./AddEditUsercategory')));

const userCategoryRoutes = [
  {
    path: '/userCategory',
    element: <UserCategoryList />,
  },
  {
    path: '/userCategory/create',
    element: <AddEditUsercategory />,
  },
  {
    path: '/userCategory/edit/:id',
    element: <AddEditUsercategory />,
  },
  {
    path: '/userCategory/:id',
    element: <UserCategoryList />,
  },
  //  /
];

export default userCategoryRoutes;
