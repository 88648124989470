import React, { useEffect, useMemo, useState } from 'react';
import useForm from 'app/components/useForm';
import styled from '@emotion/styled';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { SimpleCard } from 'app/components';
import { Validate } from './ValidateUser';
import axios from 'axios.js';
import { ADDUSER, GETALLUSERCATEGORIES, GETUSERBYID, UPDATEUSER } from 'app/components/ActionUrl';
import { useNavigate, useParams } from 'react-router-dom';
// import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const AddEditUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [userCategory, setUserCategory] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  console.log(id);
  const navigate = useNavigate();
  const { formStates, handleInputChange, handleSubmit, resetForm, errors } = useForm(
    {
      name: '',
      mobile: '',
      email: '',
      address: '',
      password: '',
      category: '',
      // status: 1,
    },
    Validate,
    userCallback
  );

  async function userCallback() {
    // add data for user
    if (id === undefined) {
      // console.log(formStates);
      const formData = new FormData();
      formData.append('name', formStates.name);
      formData.append('mobile', formStates.mobile);
      formData.append('email', formStates.email);
      formData.append('address', formStates.address);
      formData.append('password', formStates.password);
      formData.append('category', formStates.category);
      formData.append('status', status);

      setLoading(true);
      axios
        .post(ADDUSER, formData)
        .then((res) => {
          setLoading(false);
          setOpen(true);

          resetForm();
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // update data for user
      const formData = new FormData();
      formData.append('name', formStates.name);
      formData.append('mobile', formStates.mobile);
      formData.append('email', formStates.email);
      formData.append('address', formStates.address);
      formData.append('category', formStates.category);
      formData.append('status', status);
      formData.append('id', id);

      setLoading(true);
      axios
        .post(UPDATEUSER, formData)
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            setOpen(true);
            setTimeout(() => {
              navigate('/user');
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
  }));
  // get id  for click edit

  useEffect(() => {
    if (id !== undefined) {
      setLoading(true);
      axios
        .get(`${GETUSERBYID}/${id}`)
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            setUserData(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  }, [id]);

  //get Category data
  useEffect(() => {
    axios
      .get(GETALLUSERCATEGORIES)
      .then((res) => {
        console.log('get Category data', res.data.data);
        setUserCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // set data click edit
  useMemo(() => {
    if (Object.keys(userData).length > 0) {
      // ;
      // console.log('data of API: ', userData);
      formStates.name = userData.name;
      formStates.mobile = userData.mobile;
      formStates.email = userData.email;
      formStates.address = userData.address;
      formStates.category = userData.user_category;
      // formStates.status = userData.status;
      setStatus(userData.status);
    }
    // console.log(userData.status);
  }, [userData]);

  const handleClose = () => {
    setOpen(false);
  };

  const hidedstatus = (e) => {
    let data = e.target.checked;
    data ? setStatus(1) : setStatus(0);
    console.log(data);
  };
  return (
    <>
      {loading ? (
        <div
          style={{ position: 'absolute', top: '50%', left: '50%' }}
          class="spinner-border text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <SimpleCard title="Create User">
          <div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    variant="outlined"
                    value={formStates.name}
                    name="name"
                    onChange={handleInputChange}
                    type="text"
                  />
                  {errors && errors.name ? <p className="text-danger">{errors.name}</p> : ''}
                </Grid>
              </Grid>
              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    value={formStates.mobile}
                    name="mobile"
                    onChange={handleInputChange}
                    type="number"
                  />
                  {errors && errors.mobile ? <p className="text-danger">{errors.mobile}</p> : ''}
                </Grid>
              </Grid>
              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={formStates.email}
                    name="email"
                    onChange={handleInputChange}
                    type="email"
                  />
                  {errors && errors.email ? <p className="text-danger">{errors.email}</p> : ''}
                </Grid>
              </Grid>
              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="address"
                    label="Address"
                    variant="outlined"
                    value={formStates.address}
                    name="address"
                    onChange={handleInputChange}
                    type="text"
                  />
                  {errors && errors.address ? <p className="text-danger">{errors.address}</p> : ''}
                </Grid>
              </Grid>
              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formStates.category}
                      label="Category"
                      onChange={handleInputChange}
                    >
                      {userCategory.map((data) => {
                        return <MenuItem value={data.id}>{data.category_name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  {errors && errors.category ? (
                    <p className="text-danger">{errors.category}</p>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              {id === undefined && (
                <>
                  <Grid container my={3}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        id="password"
                        label="Password"
                        variant="outlined"
                        value={formStates.password}
                        name="password"
                        onChange={handleInputChange}
                        type="password"
                      />
                      {errors && errors.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container my={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <FormControlLabel
                    control={<Switch checked={status} color="primary" onChange={hidedstatus} />}
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <StyledButton type="submit" variant="contained" color="primary">
                Submit
              </StyledButton>
            </form>
          </div>
        </SimpleCard>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} variant="filled">
          {/* {console.log('idtest:', id)} */}

          {id == undefined ? 'User Add Successfully' : 'User Update Successfully'}
        </Alert>
      </Snackbar>
      ;
    </>
  );
};

export default AddEditUser;
