import React from 'react';
import { useState } from 'react';
import { RMIUploader } from 'react-multiple-image-uploader';

const TestComponents = () => {
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const handleSetVisible = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    setImages(data);
    console.log('Upload files', data);
  };
  const onSelect = (data) => {
    console.log('Select files', data);
  };
  const onRemove = (id) => {
    console.log('Remove image id', id);
  };

  return (
    <div className="App">
      <RMIUploader
        isOpen={visible}
        onChange={() => {}}
        hideModal={hideModal}
        onSelect={onSelect}
        onUpload={onUpload}
        onRemove={onRemove}
        dataSources={images}
      />
    </div>
  );
};

export default TestComponents;
