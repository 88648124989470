// const API = 'http://127.0.0.1:8000';
const API = 'https://desapi.descomponents.com/public';

export const IMAGE_URL = 'https://desapi.descomponents.com/public/';

//Login API
export const LOGIN = API + '/api/login';

//UserCategory API

export const ADDUSERCATEGORY = API + '/api/addUserCategory';
export const UPDATEUSERCATEGORY = API + '/api/updateUserCategory';
export const DELETEUSERCATEGORY = API + '/api/deleteUserCategory';
export const GETUSERCATEGORIES = API + '/api/getUserCategories';
export const GETALLUSERCATEGORIES = API + '/api/getAllUserCategories';
export const GETUSERCATEGORYBYID = API + '/api/getUserCategoryById';

//ProductCategory API
export const GETPRODUCTCATEGORIES = API + '/api/getProductCategories';
export const GETALLPRODUCTCATEGORIES = API + '/api/getAllProductCategories';
export const ADDPRODUCTCATEGORY = API + '/api/addProductCategory';
export const UPDATEPRODUCTCATEGORY = API + '/api/updateProductCategory';
export const DELETEPRODUCTCATEGORY = API + '/api/deleteProductCategory';
export const GETPRODUCTCATEGORYBYID = API + '/api/getProductCategoryById';

//Product API

export const ADDPRODUCTDETAILS = API + '/api/AddProductDetails';
export const UPDATEPRODUCT = API + '/api/updateProduct';
export const GETALLPRODUCTS = API + '/api/getAllProducts';
export const ADDPRODUCT = API + '/api/addProduct';
export const DELETEPRODUCT = API + '/api/deleteProduct';

export const GETPRODUCTBYID = API + '/api/getProductById';

//User API

export const GETALLUSERS = API + '/api/getAllUsers';
export const ADDUSER = API + '/api/addUser';
export const DELETEUSER = API + '/api/deleteUser';
export const GETUSERBYID = API + '/api/getUserById';
export const UPDATEUSER = API + '/api/updateUser';

//CartItem API
export const GETCARTITEMS = API + '/api/getCartItems';
export const GETUPDATESTATUS = API + '/api/updateOrderStatus';
export const DELETECARTITEM = API + '/api/deleteCartItem';
export const GETCARTITEMBYORDERID = API + '/api/getCartItemsByOrderId';

//Product Details

export const UPDATEPRODUCTDETAILS = API + '/api/updateProductDetails';

//Product Details
export const GETALLORDERS = API + '/api/getAllOrders';

//Order_status api
export const UPDATEALLORDERSTATUS = API + '/api/updateAllOrderStatus';
export const UPDATEPRODUCTPRICE = API + '/api/updateProductPrice';
