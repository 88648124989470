import { useState } from 'react';

const useForm = (initialValues, validate, callback) => {
  const [formStates, setFormStates] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate) {
      const validationErrors = validate(formStates);
      const noErrors = Object.keys(validationErrors).length === 0;
      setErrors(validationErrors);
      if (noErrors) {
        callback();
      } else {
        console.log('errors try again', validationErrors);
      }
    } else {
      callback();
    }
  };

  const resetForm = () => {
    setFormStates(initialValues);
  };

  const handleInputChange = (event) => {
    // event.persist();
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.value,
    }));
    if (validate && event.target.value.length > 0) {
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.value,
    }));
    setFormStates((formStates) => ({
      ...formStates,
      ['cursorPosition']: event.target.selectionStart,
    }));
    setFormStates((formStates) => ({
      ...formStates,
      ['tempType']: event.target.name,
    }));

    if (validate && event.target.value.length > 0) {
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  const handleCheckboxInputChange = (event) => {
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.checked,
    }));

    if (validate && event.target.value.length > 0) {
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  const handleFileInputChange = (event) => {
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.files[0],
    }));

    let imageType = event.target.files[0].type;

    if (
      event.target.files[0].type &&
      imageType.indexOf('png') === -1 &&
      imageType.indexOf('jpg') === -1 &&
      imageType.indexOf('jpeg') === -1
    ) {
      let msg = 'File does not support. You must use .png or .jpg';
      // setErrors((errors) => ({ ...errors, [event.target.name]: msg }))
    } else {
      console.log('ff', event.target.name);
      console.log('err', errors);
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  // For PDF accept
  const handleFileInputPdfChange = (event) => {
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.files[0],
    }));

    let fileType = event.target.files[0].type;

    if (event.target.files[0].type && fileType.indexOf('pdf') === -1) {
      let msg = 'File does not support. You must use .pdf';
      // setErrors((errors) => ({ ...errors, [event.target.name]: msg }))
    } else {
      console.log('ff', event.target.name);
      console.log('err', errors);
      // setErrors((errors) => ({ ...errors, [event.target.name]: '' }))
    }
  };

  const handleDualListbox = (selected, name) => {
    setFormStates((formStates) => ({ ...formStates, [name]: selected }));
  };

  const handleNumberInputChange = (event) => {
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: Number(event.target.value),
    }));
    if (validate && event.target.value.length > 0) {
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  const handleColorPicker = (name, value) => {
    setFormStates((formStates) => ({ ...formStates, [name]: value }));
    if (validate && name) {
      setErrors((errors) => ({ ...errors, [name]: '' }));
    }
  };
  const handleCustomeInput = (name, value) => {
    setFormStates((formStates) => ({ ...formStates, [name]: value }));
    if (validate && name) {
      setErrors((errors) => ({ ...errors, [name]: '' }));
    }
  };

  const setImage = (value, name) => {
    setFormStates((formStates) => ({ ...formStates, [name]: value }));
    if (validate && name) {
      setErrors((errors) => ({ ...errors, [name]: '' }));
    }
  };
  const resetColor = (value, name) => {
    setFormStates((formStates) => ({ ...formStates, [name]: value }));
    if (validate && name) {
      setErrors((errors) => ({ ...errors, [name]: '' }));
    }
  };

  const handleSwitchInput = (event) => {
    setFormStates((formStates) => ({
      ...formStates,
      [event.target.name]: event.target.checked,
    }));

    if (validate && event.target.value.length > 0) {
      setErrors((errors) => ({ ...errors, [event.target.name]: '' }));
    }
  };

  return {
    handleSubmit,
    handleInputChange,
    handleNumberInputChange,
    handleCheckboxInputChange,
    handleFileInputChange,
    handleFileInputPdfChange,
    handleDualListbox,
    handleColorPicker,
    handleCustomeInput,
    setImage,
    resetColor,
    formStates,
    errors,
    resetForm,
    handleEmailInputChange,
    handleSwitchInput,
  };
};

export default useForm;
