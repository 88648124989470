import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import userRoutes from 'app/views/users/userRoutes';
import userCategoryRoutes from 'app/views/userCategory/userCategoryRoutes';
import productPriceMasterRoutes from 'app/views/productPriceMaster/productPriceMasterRoutes';
import productRoutes from 'app/views/products/productRoutes';
import productCategoryRoutes from 'app/views/productCategory/productCategoryRoutes.js';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import cartItemRoutes from './views/cartItems/cartItemRoutes';
import registerRoutes from './views/registers/registerRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...userRoutes,
      ...userCategoryRoutes,
      ...productRoutes,
      ...productCategoryRoutes,
      ...cartItemRoutes,
      ...registerRoutes,
      ...productPriceMasterRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
