import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ProductCategoryList = Loadable(lazy(() => import('./ProductCategoryList')));
const AddEditProductCategory = Loadable(lazy(() => import('./AddEditProductCategory')));

const productCategoryRoutes = [
  {
    path: '/productCategory',
    element: <ProductCategoryList />,
  },
  {
    path: '/productCategory/create',
    element: <AddEditProductCategory />,
  },
  {
    path: '/productCategory/edit/:id',
    element: <AddEditProductCategory />,
  },
  {
    path: '/productCategory/delete/:id',
    element: <ProductCategoryList />,
  },
  //  /
];

export default productCategoryRoutes;
