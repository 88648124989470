import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import AddEditUser from './AddEditUser';

const UserList = Loadable(lazy(() => import('./UserList')));

const userRoutes = [
  {
    path: '/user',
    element: <UserList />,
  },
  {
    path: '/user/create',
    element: <AddEditUser />,
  },
  {
    path: '/user/edit/:id',
    element: <AddEditUser />,
  },
  {
    path: '/user/delete/:id',
    element: <UserList />,
  },

  //  /
];

export default userRoutes;
