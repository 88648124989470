export const Validate = (inputs) => {
  const errors = {};
  if (!inputs.name) {
    errors.name = 'Name is required';
  }
  if (!inputs.mobile) {
    errors.mobile = 'Mobile is required';
  }
  if (!inputs.email) {
    errors.email = 'email is required';
  }
  // if (!inputs.password) {
  //   errors.password = 'password is required';
  // }
  if (!inputs.category) {
    errors.category = 'category is required';
  }

  return errors;
};
